<template>
  <tbody>
    <template v-for="scope,index in complexScopes">
      <b-tr :key="'scope_desc'+scope.id">
        <b-td>
          <b-badge
            pill
            :style="'background-color:'+(colors[index]?colors[index]:'#311B92')"
          >
            {{ scope.name }}
          </b-badge>
          <br>
          <span class="text-muted">
            {{ scope.description }}
          </span>
        </b-td>
        <b-td v-if="showColumnsFiltered('internal_note')">
          <b-button
              class="py-0"
              variant="light"
              style="background-color: transparent!important; border: 0px; height: 20px"
              @click="scope.is_collapsed=!scope.is_collapsed"
          >
            <uil-angle-right-b v-if="!scope.is_collapsed" />
            <uil-angle-down
                v-else
                class="icon"
            />
          </b-button>
        </b-td>
        <b-td v-if="showColumnsFiltered('uniformat_code')" />
        <b-td v-if="showColumnsFiltered('is_base_spec')" />
        <b-td v-if="showColumnsFiltered('rebate_total')">
          <div
            :class="[scope.rebate_total < 0 ? 'credit' : '']"
            style="text-align: center"
          >
            {{
              formatter.format(scope.rebate_total)
            }}
          </div>
        </b-td>
        <b-td v-if="showColumnsFiltered('price_per_sqft')">
          <div
            :class="[scope.price_per_sqft < 0 ? 'credit' : '']"
            style="text-align: center"
          >
            {{
              formatter.format(scope.price_per_sqft)
            }}
          </div>
        </b-td>
        <b-td v-if="showColumnsFiltered('total_price')">
          {{
           formatter.format(scope.total_price)
          }}
        </b-td>
        <b-td v-if="showColumnsFiltered('cost_per_sqft')">
          <div
            class="cost-line"
            style="text-align: center"
          >
            {{
              formatter.format(scope.cost_per_sqft)
            }}
          </div>
        </b-td>
        <b-td v-if="showColumnsFiltered('total_cost')">
          <div
            class="cost-line"
            style="text-align: center"
          >
            {{
              formatter.format(scope.total_cost)
            }}
          </div>
        </b-td>
        <b-td :class="visibleActions">
          <div class="text-nowrap">
            <a
              @click="editComplexScope(scope)"
            >
              <feather-icon
                id="tooltip-eye"
                class="mx-1"
                icon="Edit3Icon"
                size="16"
                style="margin-right: 8px !important"
              />
              <span
                class="align-middle"
                style="margin-right: 15px"
              >Edit</span>
            </a>
            <b-tooltip
              target="tooltip-eye"
              title="Edit Option"
            />
          </div>
        </b-td>
      </b-tr>
      <template v-if="scope.items.length>0 && scope.is_collapsed">
        <b-tr
          v-for="item,idx in scope.items"
          class="bg-light"
          :key="item.id+'scope_item'+idx"
        >
          <b-td>
            <div
              class="ml-2"
              style="max-width: 400px"
            >
              {{ item.name }}
              <span
                v-if="item.details && item.complex_scope_name ==null"
                style="
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 18px;
                                  letter-spacing: 0px;
                                  text-align: left;
                                  color: #b9b9c3;
                                  white-space: pre-line;
                                "
              >
                <br>
                {{ item.details }}
              </span>
            </div>
          </b-td>
          <b-td v-if="showColumnsFiltered('internal_note')">
            <div
              v-if="item.internal_note!==null"
              v-html="item.internal_note.replace(/\n/g, '<br>')"
            />
          </b-td>
          <b-td v-if="showColumnsFiltered('uniformat_code')">
            <div v-if="item.uniformat!==null">{{
              searchUniformatCodeName('Construction', item.uniformat)
            }}
            </div>
          </b-td>
          <b-td v-if="showColumnsFiltered('is_base_spec')">
            <div
              v-if="item.is_base_spec"
              style="text-align: center"
            >
              <feather-icon
                class="align-middle text-body"
                icon="CheckIcon"
                size="16"
              />
            </div>
            <div
              v-else
              style="text-align: center"
            >
              <feather-icon
                class="align-middle text-body"
                icon="XIcon"
                size="16"
              />
            </div>
          </b-td>
          <b-td v-if="showColumnsFiltered('rebate_total')">
            <div
              :class="[item.rebate_total < 0 ? 'credit' : '']"
              style="text-align: center"
            >
              {{
                Number(item.rebate_total)
                  .toLocaleString('en-CA', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'CAD',
                  })
              }}
            </div>
          </b-td>
          <b-td v-if="showColumnsFiltered('price_per_sqft')">
            <div
              :class="[item.price_per_sqft < 0 ? 'credit' : '']"
              style="text-align: center"
            >
              {{
                Number(item.price_per_sqft)
                  .toLocaleString('en-CA', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'CAD',
                  })
              }}
            </div>
          </b-td>
          <b-td v-if="showColumnsFiltered('total_price')">
            <div
              :class="[item.total_price < 0 ? 'credit' : '']"
              style="text-align: center"
            >
              {{
                Number((item.total_price))
                  .toLocaleString('en-CA', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'CAD',
                  })
              }}
            </div>
          </b-td>
          <b-td v-if="showColumnsFiltered('cost_per_sqft')">
            <div
              class="cost-line"
              style="text-align: center"
            >
              <uil-arrow-growth
                v-if="item.is_adjustment === true"
                style="color: #ff9f43"
              />
              {{
                Number((item.cost_per_sqft))
                  .toLocaleString('en-CA', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'CAD',
                  })
              }}
            </div>
          </b-td>
          <b-td v-if="showColumnsFiltered('total_cost')">
            <div
              class="cost-line"
              style="text-align: center"
            >
              <uil-arrow-growth
                v-if="item.is_adjustment === true"
                style="color: #ff9f43"
              />
              {{
                Number((item.total_cost))
                  .toLocaleString('en-CA', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'CAD',
                  })
              }}
            </div>
          </b-td>
          <b-td :class="visibleActions">
            <div class="text-nowrap">
              <a
                v-b-modal.edit-options-modal
                @click="editOption(item)"
              >
                <feather-icon
                  id="tooltip-eye"
                  class="mx-1"
                  icon="Edit3Icon"
                  size="16"
                  style="margin-right: 8px !important"
                />
                <span
                  class="align-middle"
                  style="margin-right: 15px"
                >Edit</span>
              </a>
              <b-tooltip
                target="tooltip-eye"
                title="Edit Option"
              />

              <!-- Dropdown -->
              <b-dropdown
                no-caret
                toggle-class="p-0"
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    class="align-middle text-body"
                    icon="MoreVerticalIcon"
                    size="16"
                  />
                </template>
                <b-dropdown-item
                  target="_blank"
                  @click="deleteFronComplexScope(item)"
                >
                  <uil-minus-circle />
                  <span class="align-middle ml-50">Remove from Complex Scope</span>
                </b-dropdown-item>
                <b-dropdown-item
                  target="_blank"
                  @click="deleteOption(item)"
                >
                  <feather-icon icon="Trash2Icon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-td>
        </b-tr>
      </template>

    </template>
  </tbody>
</template>

<script>
import {
  BBadge, BButton, BDropdown, BDropdownItem, BTd, BTooltip, BTr,
} from 'bootstrap-vue'
import {
  UilAngleRightB,
  UilAngleDown,
  UilMinusCircle,
} from '@iconscout/vue-unicons'
import uniformat from '@/assets/objects/uniformat-codes.json'

export default {
  components: {
    BBadge,
    BDropdown,
    BTr,
    BButton,
    BTooltip,
    BTd,
    BDropdownItem,
    UilAngleRightB,
    UilAngleDown,
    UilMinusCircle,
  },
  props: {
    visibleActions: {
      type: Boolean,
      default: false,
    },
    complexScopes: {
      type: Array,
    },
    displayColumnsCheck: {
      type: Array,
    },
  },
  data() {
    return {
      uniformat_codes: uniformat,
      formatter: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      colors: [
        "#1E88E5", "#D81B60", "#43A047", "#8E24AA", "#FB8C00",
        "#3949AB", "#F4511E", "#00ACC1", "#546E7A", "#6D4C41",
        "#C62828", "#AD1457", "#2E7D32", "#283593", "#F9A825",
        "#EF6C00", "#00897B", "#5E35B1", "#D84315", "#455A64",
        "#4E342E", "#1565C0", "#B71C1C", "#880E4F", "#1B5E20",
        "#4A148C", "#E65100", "#0D47A1", "#3E2723", "#311B92"
      ]
    }
  },
  methods: {
    showColumnsFiltered(col) {
      let isSelected = false
      if (this.displayColumnsCheck.length > 0) {
        const res = this.displayColumnsCheck.filter(c => c === col)
        if (res.length !== 0) {
          isSelected = true
        }
      }
      return isSelected
    },
    deleteOption(item) {
      this.$emit('deleteOption', item)
    },
    deleteFronComplexScope(item) {
      this.$emit('deleteComplexItem', item)
    },
    editOption(item) {
      this.$emit('editOption', item)
    },
    editComplexScope(item) {
      this.$emit('editComplexScope', item)
    },
    searchUniformatCodeName(subgroup, code) {
      let codeName = ''
      this.uniformat_codes[subgroup].map(u => {
        if (u.code == code) {
          codeName = u.code_name
        }
      })
      return codeName
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
